import React from "react";

import stock from "../img/image1.jpg";
import chat from "../img/chatbot.jpg";
import den from "../img/den.jpg";
import sudoku from "../img/sudoku.jpg";
import music from "../img/music.png";
import byow from "../img/byow.png";

class Portfolio extends React.Component {
  render() {
    return (
      <section id="work" className="portfolio-mf sect-pt4 route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="title-box text-center">
                <h3 className="title-a">Projects</h3>
                <p className="subtitle-a">
                  Some of the projects that I have worked on!
                </p>
                <div className="line-mf"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="work-box">
                <a href="https://github.com/Mehul-Jaiswal">
                  <div className="work-img">
                    <img src={stock} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-8">
                        <h2 className="w-title">Stock Analyzer and Predictor</h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                            Python, Flask API, Machine Learning, Tensorflow, Pandas
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="w-like">
                          <span className="ion-ios-plus-outline"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="work-box">
                <a href="https://devpost.com/software/genai-173sk0">
                  <div className="work-img">
                    <img src={chat} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-8">
                        <h2 className="w-title">Emotional AI Therapy Chatbot</h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                            Python, Anaconda, React.js, Firebase, APIs, Data Processing, Hume API, OpenAI API
                          </span>{" "}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="w-like">
                          <span className="ion-ios-plus-outline"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="work-box">
                <a href="https://devpost.com/software/housing-9-0">
                  <div className="work-img">
                    <img src={den} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-8">
                        <h2 className="w-title">Find Your Den</h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                          HTML, CSS3, Javasript, Firebase, Data Processing, GoogleAPI
                          </span>
                          {/*} / <span className="w-date">18 Sep. 2018</span>*/}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="w-like">
                          <span className="ion-ios-plus-outline"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="work-box">
                <a href="https://github.com/Mehul-Jaiswal/Sudoku">
                  <div className="work-img">
                    <img src={sudoku} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-8">
                        <h2 className="w-title">Sudoku Solver</h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                          Python, Data Structures
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="w-like">
                          <span className="ion-ios-plus-outline"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="work-box">
                <a href="https://github.com/Mehul-Jaiswal/Course-Project_CIS-22c-Music">
                  <div className="work-img">
                    <img src={music} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-8">
                        <h2 className="w-title">Music Store Franchise</h2>
                        <div className="w-more">
                          <span className="w-ctegory">Java, Data Structure, SQL, React</span>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="w-like">
                          <span className="ion-ios-plus-outline"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="work-box">
                <a href="https://github.com/Mehul-Jaiswal">
                  <div className="work-img">
                    <img src={byow} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-8">
                        <h2 className="w-title">BYOW: Game</h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                            Java, Data Structures
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="w-like">
                          <span className="ion-ios-plus-outline"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Portfolio;
